import Moment from "react-moment"
import { EyeIcon, DocumentTextIcon, ShoppingCartIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleLeftIcon,ChevronDoubleRightIcon, MapPinIcon, BuildingOffice2Icon, MapIcon, BuildingOfficeIcon } from "@heroicons/react/24/outline";
import { apiUrl } from '../../bundles/herlpers/api'
import 'moment/locale/es';
/** Rev */

const Table = ({data,setIndicador,setPay,setPays, getData, pagina, paginas, total}) => {       

  let renderPageNumbers;
    const pageNumber = [];
    if (total !== null) {
        for (let i = 1; i <= paginas; i++) {
          pageNumber.push(i);
        }
        renderPageNumbers = pageNumber.map((number) => {
          let classes = pagina === number ? "rounded h-6 w-6 mr-1 border-none items-center bg-slate-400 text-gray-50 font-bold" 
          : "rounded h-6 w-6 mr-1 border items-center border-stone-300 bg-gradient-to-t from-stone-200 to-stone-100 text-gray-400 font-bold";
          if (
            number === 1 ||
            number === total ||
            (number >= pagina - 2 && number <= pagina + 2)
          ) {
            return (
              <button
                key={number}
                className={classes}
                onClick={() => getData(number)}
              >
                {number}
              </button>
            );
          } else {
            return null;
          }
        });
    }

return (      
  <div className="h-max  w-full ">    
    <div className="h-540 overflow-auto items-center justify-center  pt-1 pl-1 pr-1">
    { data.map((ite,index)=> (
      <div 
      key={index}       
      className='h-32 w-full lg:w-96 p-1 rounded float-left flex-col mb-1 bg-stone-200 border border-gray-300 mr-1'>          
        <div className="h-2/6 w-full flex text-gray-600  ">
          <div className="h-10 w-10 flex items-center justify-center">
                <img alt=".."
                     className="h-8 w-8 rounded-full"          
                     src={`${apiUrl}static/images/clientes/sm/` + ite.filename}
                />
          </div>
          <div className="h-10 w-7/12 flex-col pl-1 flex justify-center">
            <p className="h-6 pt-1 text-[11px] font-bold">{ ite.nombres}</p>
            <p className="h-4 text-[8px] ">
              <Moment format="ll">{ ite.actualizacion}</Moment>
            </p>
          </div>
          <div className="h-10 w-4/12 flex justify-between items-center">
            <button 
            onClick={() => setIndicador(ite.id)}
            className='h-6 w-6 bg-red-400 text-slate-100 hover:bg-red-300  rounded flex items-center justify-center'>
                <EyeIcon className="h-4 w-4 " />  
            </button>
            {ite.nivel === "Comercial" ?
            <>
            <button 
            onClick={() => setPay(ite.id)}
            className='h-6 w-6 bg-sky-400 text-slate-100 hover:bg-sky-300  rounded flex items-center justify-center'>
              <DocumentTextIcon className="h-4 w-4 " />  
            </button>
            <button 
            onClick={() => setPays(ite.id)}
            className='h-6 w-6 bg-orange-400 text-slate-100 hover:bg-orange-300  rounded flex items-center justify-center'>
              <ShoppingCartIcon className="h-4 w-4 " />  
            </button>
            </>
            :null}            
          </div>
        </div> 

        <div className="h-4/6 w-full flex bg-stone-100">
          <div className="h-20 w-2/6 p-1 bg-stone-100 flex items-center justify-center border-r border-gray-200">
                <img alt=".."
                    className="h-16 rounded"          
                    src={`${apiUrl}static/images/portadas/md/` + ite.portada}
                />
          </div> 
          <div className="h-20 w-4/6 pl-1 text-[10px] text-gray-500">              
            <div className="h-6 w-full border-b border-gray-200 flex">
                <div className="flex w-1/12 items-center justify-center">
                  <BuildingOfficeIcon className="h-5 w-5 text-gray-400" />
                </div>      
                <div className="flex w-11/12 items-center pl-1">
                  {ite.nombres}
                </div>                              
            </div>
            <div className="h-6 w-full border-b border-gray-200 flex">
                <div className="flex w-1/12 items-center justify-center">
                  <BuildingOffice2Icon className="h-5 w-5 text-gray-400" />
                </div>      
                <div className="flex w-11/12 items-center pl-1">
                  {ite.direccion}
                </div>                        
            </div>            
            <div className="h-6 w-full border-b border-gray-200 flex">                         
                <div className="flex w-1/12 items-center justify-center">
                  <MapIcon className="h-5 w-5 text-gray-400" />
                </div>      
                <div className="flex w-7/12 items-center pl-1">
                  {ite.ciudad}
                </div>   
                <div className="flex w-1/12 items-center justify-center">
                  <MapPinIcon className="h-5 w-5 text-gray-400" />
                </div>      
                <div className="flex w-3/12 items-center pl-1">
                  {ite.distancia} Km.
                </div>   
            </div>        
          </div> 
        </div>           
    </div>
    ))}  
    </div>
    <div className="h-9  bg-gray-300 flex border border-gray-300">
        <div className="w-2/6 flex justify-start items-center">
              <div className='h-6 w-1/2 flex items-center'>
              <span className='w-32 text-[11px] text-stone-600 pl-2'> {paginas} de {total} items </span>
              </div>    
              <div className='h-6 w-1/2 flex items-center'>                 
              </div>    
        </div>
        <div className="w-4/6 flex justify-end">
            <ul className="flex p-1 text-[10px]">                
                    <li 
                    className={pagina === 1 ? "border rounded bg-gradient-to-t from-stone-200 to-stone-100 border-stone-300 flex items-center justify-center h-6 w-6 text-stone-500 mr-1 cursor-not-allowed p-1": "border rounded bg-gradient-to-t from-stone-200 to-stone-100 border-stone-300 flex items-center h-6 w-6 text-stone-400 mr-1 hover:bg-stone-200 justify-center p-1" } 
                    onClick={() => getData(pagina === 1 ? 0 : 1)}>        
                    <ChevronDoubleLeftIcon className="h-5 w-5 text-stone-400"/>
                    </li>

                    <li
                    className={pagina === 1 ? "border rounded bg-gradient-to-t from-stone-200 to-stone-100 border-stone-300 flex items-center h-6 w-6 justify-center text-stone-400 mr-1 cursor-not-allowed p-1": "border rounded bg-gradient-to-t from-stone-200 to-stone-100 border-stone-300 flex items-center h-6 w-6 text-stone-400 mr-1 hover:bg-stone-200 justify-center p-1" } 
                    onClick={() =>getData(pagina === 1 ? 0: pagina - 1)}>        
                    <ChevronLeftIcon className="h-5 w-5 text-stone-400" />
                    </li>

                    <li
                    className="h-6 flex items-center">        
                    {renderPageNumbers}
                    </li>        
                    
                    <li        
                    className={pagina === paginas ? "border rounded bg-gradient-to-t from-stone-200 to-stone-100 border-stone-300 flex items-center justify-center h-6 w-6 text-stone-400 mr-1 cursor-not-allowed p-1": "border rounded bg-gradient-to-t from-stone-200 to-stone-100 border-stone-300 flex items-center h-6 w-6 text-stone-500 mr-1 hover:bg-stone-200 justify-center p-1" } 
                    onClick={() => getData(pagina === paginas ? 0 : pagina + 1)}>          
                    <ChevronRightIcon className="h-5 w-5 text-stone-400" />
                    </li>

                    <li
                    className={paginas === pagina ? "border rounded bg-gradient-to-t from-stone-200 to-stone-100 border-stone-300  flex items-center justify-center h-6 w-6 text-stone-400 mr-1 cursor-not-allowed p-1": "border rounded bg-gradient-to-t from-stone-200 to-stone-100 border-stone-200 h-6 w-6 text-stone-500 mr-1 hover:bg-stone-200 flex items-center justify-center p-1" } 
                    onClick={() => getData(pagina === paginas ? 0: paginas)}>
                    <ChevronDoubleRightIcon className="h-4 w-4 text-stone-500" />  
                    </li>     
                </ul>
        </div> 
    </div>
    
  </div>  
  );
}

export default Table;
