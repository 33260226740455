import { useDispatch, useSelector } from "react-redux";
/*import { setLista } from '@inventario/reducers/productoSlice'*/
import { setItems, setTotal, setCantidad, setItem } from '../../reducers/tpdvSlice'
import {toastr} from 'react-redux-toastr'
import { MinusIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";

const Items = () => {
    const dispatch = useDispatch()      
    const { _data } = useSelector((state) => state.clientes)
    const { vitems, item } = useSelector((state) => state.tpdv)     
    let ep  = JSON.parse(localStorage.getItem('@xmoneda'))
    

    const handleRes = (ii) =>{        
        let va = parseInt(vitems[ii].stock)       
        let vb = parseInt(vitems[ii].cantidad)  

        if(vb > 0){
            console.log("el ultimo",vb)
            let newCantidad = parseInt(vitems[ii].cantidad) - 1
            let newTotal    = parseFloat(vitems[ii].subTotal) - parseFloat(vitems[ii].valor)              
            
            const newArr = vitems.map((obj, key) => {
            if (key === ii) {
                return {...obj, cantidad: newCantidad, subTotal: newTotal};
                }          
                return obj;
            });

            if(newCantidad === 0){
                newArr.splice(ii, 1);                
            }            
            dispatch(setItems(newArr))            
            let nc = parseInt(item.nroItems) - 1
            /*dispatch(setCantidad(nc))*/

            let nn = parseFloat(item.totalGeneral) - parseFloat(vitems[ii].valor)
            /*dispatch(setTotal(nn))*/
            let ion= {...item}
            ion.cantidad     = nc
            ion.subTotal     = nn
            ion.totalGeneral = nn
            dispatch(setItem(ion))
            parametrizar(vitems[ii].productoId,'res')

        }
        
        

    }

    const handleAdd = (ii) =>{        
        let va = parseInt(vitems[ii].stock)       
        let vb = parseInt(vitems[ii].cantidad) 

        if(vb < va){
            let newCantidad = parseInt(vitems[ii].cantidad) + 1
            let newTotal    = parseFloat(vitems[ii].subTotal) + parseFloat(vitems[ii].valor)              
            
            const newArr = vitems.map((obj, key) => {
            if (key === ii) {
                return {...obj, cantidad: newCantidad, subTotal: newTotal};
                }          
                return obj;
            });

            dispatch(setItems(newArr))            

            let nc = parseInt(item.nroItems) + 1
            /*dispatch(setCantidad(nc))*/

            let nn = parseFloat(item.totalGeneral) + parseFloat(vitems[ii].valor)
            /*dispatch(setTotal(nn))*/
            let ion= {...item}
            ion.cantidad     = nc
            ion.subTotal     = nn
            ion.totalGeneral = nn
            dispatch(setItem(ion))
            /*parametrizar(vitems[ii].productoId,'res')
            descontar(vitems[ii].productoId)*/
            parametrizar(vitems[ii].id,'res')
            descontar(vitems[ii].id)
            }else{
                toastr.error('Stock', 'No existe Stock') 
            }

    }

    const descontar = (id) =>{

        let resu = _data.findIndex(item => item.id === id);          
        let nn = [..._data]
         
         let newProductos = nn.map((tt,index)=>{                        
            let nn = tt.stock
            if(index === resu){
                nn = nn - 1 
            }
            let iok = {
                categoria : tt.categoria,
                codigo    : tt.codigo,
                filename  : tt.filename,
                id        : tt.id,
                productoId : tt.productoId,
                tipo       : tt.tipo,
                marca     : tt.marca,
                nombre    : tt.nombre,
                precioVenta : tt.precioVenta,      
                precioUnidad : tt.precioUnidad,          
                stock     : nn,
                unidad    : tt.unidad
            }
            return iok
        })         
        /*dispatch(setLista(newProductos))*/
    }
  
    const handleDel = (ii) =>{
        let newCantidad = parseInt(vitems[ii].cantidad)
        let newTotal    = parseFloat(vitems[ii].subTotal)              

        let newArray = [...vitems]
        newArray.splice(ii, 1);
        dispatch(setItems(newArray))

        let nc = parseInt(item.nroItems) - parseFloat(newCantidad)
            /*dispatch(setCantidad(nc))*/

            let nn = parseFloat(item.totalGeneral) - parseFloat(newTotal)
            /*dispatch(setTotal(nn))*/
            let ion= {...item}
            ion.cantidad     = nc
            ion.subTotal     = nn
            ion.totalGeneral = nn
            dispatch(setItem(ion))
            param(ii)
            
            
    }

    const param = (id) =>{      
        let va = parseInt(vitems[id].stock) 
        /*let vb = vitems[id].productoId*/
        let vb = vitems[id].id
        
        const newArr = _data.map((obj, key) => {
            if (obj.id === vb) {
                return {...obj, stock: va};
                }          
                return obj;
            });
               
       /* dispatch(setLista(newArr))        */
    }

    const parametrizar = (id,type) =>{      
        let resu = _data.findIndex(item => item.id === id);        
        
        let nn = [..._data]
         
         let newProductos = nn.map((tt,index)=>{                        
            let nn = tt.stock
            if(index === resu){
                nn = nn + 1                
            }
            let iok = {
                categoria : tt.categoria,
                codigo    : tt.codigo,
                filename  : tt.filename,
                id        : tt.id,
                productoId : tt.productoId,
                tipo       : tt.tipo,
                marca     : tt.marca,
                nombre    : tt.nombre,
                precioVenta : tt.precioVenta,                
                precioUnidad : tt.precioUnidad,
                stock     : nn,
                unidad    : tt.unidad
            }
            return iok
        })         
      /*  dispatch(setLista(newProductos))        */
    }
        

    
return (
    <div className="h-max w-full flex-col ">       
        <div className="h-490 w-full overflow-auto border border-gray-300 p-1 bg-stone-100 rounded">
        { vitems.length > 0 ?            

            vitems.map((itt,index)=>(                        
            <div key={index} className="h-10 w-full border-b solid border-gray-300 flex-col text-gray-600">
               <section className="h-5 text-[10px] w-full flex items-center pl-1">
                <span className="w-10/12" >{itt.nombre} </span>
                <div className="w-2/12 flex justify-center pt-1">
                        <button 
                            onClick={()=> handleRes(index)}
                            type="button"
                            className='w-5 h-5 text-stone-400  flex justify-center items-center hover:text-stone-500'>
                            <MinusIcon className="h-4 w-4"/> 
                        </button>
                        <button 
                            onClick={()=> handleAdd(index)}
                            type="button"
                            className='w-5 h-5   text-stone-400  flex justify-center items-center hover:text-stone-500'>
                            <PlusIcon className="h-4 w-4"/>
                        </button>
                        <button 
                            onClick={()=> handleDel(index)}
                            type="button"
                            className='w-5 h-5 rounded-r  text-stone-400 flex justify-center items-center hover:text-stone-500'>
                            <TrashIcon className="h-4 w-4"/>
                        </button>   
                </div>
               </section>     
               <section className="h-5 text-[10px] w-full flex italic">
                    <span className="w-3/12 pl-2" >{itt.cantidad} {itt.unidad} </span>
                    <span className="w-3/12" >
                    p.u. /  {new Intl.NumberFormat('es-'+ep.moneda,{minimumFractionDigits: 2}).format(itt.valor)}                                                  
                    </span>                    
                    <span className="w-6/12 text-center pr-4">
                    {new Intl.NumberFormat('es-'+ep.moneda,{minimumFractionDigits: 2}).format(itt.subTotal)} 
                    </span>                    
               </section>     
            </div>
            ))              
            : null                 
        }   
        { 
           vitems.length > 0 ? 
           <div className="h-max w-full text-[10px] bg-stone-50 flex-col text-gray-500 border rounded border-gray-200 mt-4">                   
               <section className="h-5  w-full flex italic items-center">
                    <span className="w-6/12 pl-2">
                        Sub-Total
                    </span>                                     
                    <span className="w-6/12 text-center pr-4" >
                    {new Intl.NumberFormat('es-'+ep.moneda,{minimumFractionDigits: 2}).format(item.subTotal  || 0)}
                    </span>                    
               </section>               
               <section className="h-5  w-full  flex italic items-center">
                    <span className="w-6/12 pl-2">
                        Descuento
                    </span>                                     
                    <span className="w-6/12 text-center pr-4" >
                    {new Intl.NumberFormat('es-'+ep.moneda,{minimumFractionDigits: 2}).format(item.descuento || 0)}
                    </span>                    
               </section>
               <section className="h-5  w-full flex italic items-center font-bold">
                    <span className="w-6/12 pl-2">
                        Total
                    </span>                                     
                    <span className="w-6/12 text-center pr-4  " >
                    {new Intl.NumberFormat('es-'+ep.moneda,{minimumFractionDigits: 2}).format(item.totalGeneral || 0)}
                    </span>                    
               </section>                    
            </div>   : null }    
        </div>             
              
    </div>
);
}

export default Items;
