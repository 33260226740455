import { useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { resetData } from '../../reducers/tpdvSlice'
import { BanknotesIcon, XMarkIcon } from "@heroicons/react/24/outline";

const Orden = ({view,setview}) => {
    const dispatch = useDispatch()         
    const { vitems, item } = useSelector((state) => state.tpdv)   
    const [bnd, setbnd] = useState('domicilio');
    let ep  = JSON.parse(localStorage.getItem('@xmoneda'))              
        
      
    const handleSubmit = event =>{
        event.preventDefault();                 
        dispatch(resetData())
        setview(false)        
    }

   return (
    <>
    { view ?
        <>
        <div className="justify-center items-center flex-1 fixed inset-0 z-50 outline-none">
            <div className="relative w-auto my-3 mx-auto max-w-xl flex-row justify-between">
                <div className="h-475 bg-stone-100  flex w-full items-center">                
                    <div className="h-470 w-1/2 flex-col bg-stone-100 border border-gray-300 p-2">
                    <div className='h-250 mt-1 overflow-auto'>
                    { vitems.length > 0 ?                                    
                    <table className='border-collapse w-full text-[9px]'>    
                        <thead className='h-5 bg-gray-100 text-gray-600'>
                            <tr>
                            <th className='text-start pl-1 border-b'>Producto</th>  
                            <th className='text-end pr-4 border-b'>SubTotal</th>  
                            </tr>
                        </thead>                                                                    
                        <tbody>
                            {vitems.map((itt,index)=>(
                                itt.cantidad > 0 ?
                                <tr key={index}>
                                    <td colSpan={2}>
                                        <table className='border-collapse w-full border-b'>
                                            <tbody className='text-gray-600'>
                                                <tr className='h-5'>
                                                    <td className="font-bold">{itt.nombre}</td>                                                                                    
                                                </tr>
                                                <tr>                                
                                                    <td className='w-3/4 italic'>{itt.cantidad} {itt.unidad} /P.Unit {new Intl.NumberFormat('es-'+ep.moneda,{minimumFractionDigits: 2}).format(itt.valor)} </td>
                                                    <td className='w-1/4 font-bold italic'>
                                                    {new Intl.NumberFormat('es-'+ep.moneda,{minimumFractionDigits: 2}).format(itt.subTotal)}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>                        
                                    </td>
                                </tr>
                                :null                
                            ))}  
                                <tr className='h-4 border-b font-bold text-gray-500 italic'>
                                    <td className='text-center'>Sub-total</td>    
                                    <td className='text-end pr-4'>
                                        {item.subTotal}
                                    </td>    
                                </tr>
                                <tr className='h-4 border-b font-bold text-gray-500 italic'>
                                    <td className='text-center'>Descuento</td>    
                                    <td className='text-end pr-4'>
                                       {item.descuento || 0}
                                    </td>    
                                </tr>
                                <tr className='h-4 border bg-gray-100 font-bold text-gray-500 italic'>
                                    <td className='text-center'>Total</td>    
                                    <td className='text-end pr-4 border'>
                                        {item.totalGeneral}
                                    </td>    
                                </tr>    
                        </tbody>          
                    </table>     : null     
                    }                               
                </div>   
                    </div>
                    <div className="h-470 w-1/2 flex-col bg-gray-200  p-2">
                        
                        <form onSubmit={handleSubmit} className="h-max pt-2 pl-4 pr-4 w-full flex-col text-[11px]  flex">                                 
                            <div className='w-full flex p-1 flex-col'>                                
                                    <label htmlFor="celular" className="h-6 flex p-1 font-bold text-gray-500">Nro. Celular</label>
                                    <div className='border w-full rounded border-stone-300'>
                                        <input
                                            type="text"
                                            name="celular"                                                                                       
                                            className="h-7 border-none rounded text-[11px]  pl-2 w-full focus:ring-0 focus:bg-slate-200 bg-stone-100 text-gray-500"/>                                            
                                    </div>                                                    
                            </div> 
                            <div className='w-full flex p-1 flex-col'>                                
                                    <label htmlFor="nombre" className="h-6 flex p-1 font-bold text-gray-500">Nombre</label>
                                    <div className='border w-full rounded border-stone-300'>
                                        <input
                                            type="text"
                                            name="nombre"                                                                                       
                                            className="h-7 border-none rounded text-[11px]  pl-2 w-full focus:ring-0 focus:bg-slate-200 bg-stone-100 text-gray-500"/>                                            
                                    </div>                                                    
                            </div>                             
                            
                            <label htmlFor="entrega" className="h-6 flex pl-2 pt-2 font-bold text-gray-500">Tipo de Entrega</label>                                                                                                                   
                            <div className='w-full flex p-1  pl-1 pr-1 '>
                                <button 
                                    type='button'
                                    onClick={() => setbnd("domicilio")}
                                    className={bnd === "domicilio" ? 'w-1/3 bg-sky-500 border-r  text-gray-50 h-7 flex rounded-l items-center justify-center':'w-1/3 bg-slate-300 border-r text-gray-600 h-7 flex rounded-l items-center justify-center' }>
                                    domicilio
                                </button>                                          
                                <button 
                                    type='button'
                                    onClick={() => setbnd("punto")}
                                    className={bnd === "punto" ? 'w-1/3 bg-sky-500 border-r  text-gray-50 h-7 flex items-center justify-center':'w-1/3 bg-slate-300 border-r  text-gray-600 h-7 flex items-center justify-center' }>
                                    entrega
                                </button>                                          
                                <button 
                                    type='button'
                                    onClick={() => setbnd("recojo")}
                                    className={bnd === "recojo" ? 'w-1/3 bg-sky-500 border-r  text-gray-50 h-7 flex rounded-r items-center justify-center':'w-1/3 bg-slate-300 border-r  text-gray-600 h-7 flex rounded-r items-center justify-center' }>
                                    recojo
                                </button>                                          
                            </div> 

                            <div className='w-full flex p-1 flex-col'>                                
                                    <label htmlFor="direccion" className="h-6 flex p-1 font-bold text-gray-500">Direccion</label>
                                    <div className='border w-full rounded border-stone-300'>
                                        <textarea
                                            type="text"
                                            name="direccion"                                                                                       
                                            rows={2}
                                            className="border-none rounded text-[11px]  pl-2 w-full focus:ring-0 focus:bg-slate-200 bg-stone-100 text-gray-500"/>                                            
                                    </div>                                                    
                            </div> 
                           
                            
                            <div className='h-20 w-full flex p-1 items-center'>                                
                                <button 
                                    type="submit"
                                    className="w-1/2 h-10 rounded-l bg-sky-400 text-xs flex items-center justify-center text-white font-bold ">                            
                                    <BanknotesIcon className="h-6 w-6 text-white" />                            
                                </button>
                                <button 
                                    type="button"
                                    onClick={() => setview(false)}
                                    className="w-1/2 h-10 rounded-r bg-red-400 text-xs flex items-center justify-center text-white font-bold ">                            
                                    <XMarkIcon className="h-6 w-6 text-white" />                            
                                </button>                                  
                            </div>
                        </form>
                    </div>    
                </div>        
            </div>             
        </div>  
        <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
    : null  }
    </>
    );
}

export default Orden;
