import React,{useState} from 'react';
import { ChevronDownIcon} from "@heroicons/react/20/solid";


const Select = ({options,option,handleChange,name,tipo,required}) => {  
  const [view, setview] = useState(false);     
    
  const handleChanges = (e,ii) => {     
    if(tipo === "local"){        
        handleChange(name,e)
    }
    if(tipo === "location"){              
      handleChange(e)    
    }
    if(tipo === "compuesto"){        
        const { textContent} = e.target        
        handleChange(name,textContent,ii)    
    }                
    setview(false)
  }  
           
  const handleExit = () =>{
    if(view){
      setview(false)         
    }      
  }   
  
  const componente = (it,index) =>{     
    if(tipo === "local"){
        return(   
            <li
            key={index}
            className="h-6 items-center flex text-stone-700 hover:bg-stone-100"
            onClick={()=>handleChanges(it.label)}>
            {it.label}                
            </li>  
        )
    }  
    if(tipo === "location"){
      return(   
          <li
          key={index}
          className="h-6 items-center flex text-stone-700 hover:bg-stone-100"
          onClick={()=>handleChanges(it)}>
          {it.label}                
          </li>  
      )
  } 
    if(tipo === "compuesto"){
        return(   
            <li                      
            key={index}
            className="h-6 items-center flex text-stone-700 hover:bg-stone-100"
            onClick={(e)=>handleChanges(e,it.indice)}>
            {it.label}
            </li>         
        )
    }  
  }
  
    const handleChangex = () =>{

    }

    return (  
      <div className="relative inline-block w-full text-[10px]">     
        <div 
        onClick={() => setview(!view)}
        className="w-full flex  rounded bg-stone-100 border border-gray-300">
          <input
            type="text"
            onChange={(e)=>handleChangex(e.target.name,e.target.value)}                                    
            value={option || ""}
            name={name}
            required={required}            
            className="h-7 w-5/6 border-none rounded  flex text-[11px] justify-start pl-2 focus:ring-0 focus:bg-slate-200 bg-stone-100 text-gray-500"
          />
          <div className='w-1/6 flex justify-center items-center text-stone-400 hover:text-stone-600'>
            <ChevronDownIcon className="h-5" />
          </div> 
        </div>  
        {view &&  
          <ul 
            onMouseLeave={() => handleExit()}
            className="absolute z-10  w-40 border bg-stone-50 rounded shadow-lg p-3">               
            { options.map((it, index) => (
                componente(it,index)                
            ))} 
          </ul>   
        }            
      </div>                  
    );
}

export default Select;

