import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import VentaProducto from '../includes/VentaProducto'
import Items from '../includes/Items'
import CateItems from '../../Clientes/includes/CateItems'
import Calculo from '../includes/Calculo'
import Orden from '../includes/Orden'
import { resetData } from '../../reducers/tpdvSlice'

const TpdvIndex = () => {
  const dispatch = useDispatch()  
  const navigate = useNavigate()    
  const { item } = useSelector((state) => state.clientes)    
  const [view, setview] = useState(false);

    
  const handleNext = () =>{
    navigate('/');
  }

  useEffect(() => {    
    return () => {
      dispatch(resetData())
    };
  }, []);

  return (                           
    <div className="h-max w-full bg-white flex-1">
      <div className='h-11 flex border bg-stone-100 text-[12px] text-gray-500 font-bold'>
          <div className='w-1/12 flex items-center pl-1'>
            <button 
            onClick={()=> handleNext()}
            className='w-7 h-7 border rounded bg-slate-400 pl-1'
            >
              <ArrowLeftIcon className="h-5 w-5 text-gray-50" />
            </button>
          </div>     
          <div className='w-4/12 flex items-center'>
                { item.nombres}  
          </div>            
          <div className='w-2/12 flex items-center'>
                { item.categoria}  
          </div>            
          <div className='w-1/12 flex items-center'>
                { item.pais}  
          </div>            
          <div className='w-1/12 flex items-center'>
                { item.ciudad}  
          </div>            
          <div className='w-3/12 flex items-center'>
                { item.direccion}  
          </div>                      
        </div>

        <div className="h-9  flex w-full  p-1 items-center">        
          <div className='flex w-full overflow-auto  bg-slate-100'>
            <CateItems/>
          </div>
        </div>
      
      <div className="h-max flex w-full ">
        <div className="w-1/3 flex border-b flex-col pr-1 pl-1">          
          <Items/>
          <Calculo setview={setview} />
        </div>
        <div className="w-2/3 flex flex-col">          
          <div className="h-535 flex w-full border border-gray-200 rounded">
           <VentaProducto/>
          </div>
        </div>
      </div> 
      <Orden view={view} setview={setview} />
    </div>
     
  );
}

export default TpdvIndex;
