import {  useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { productosData  } from '../../reducers/clienteSlice'

const CateItems = () => {    
    const dispatch = useDispatch() 
    const { c_items} = useSelector((state) => state.cate)    
    const { item, ind } = useSelector((state) => state.clientes)
    const [sta, setsta] = useState(0);
    
    const handleSearch = (it) =>{
        setsta(it)
        dispatch(productosData({pagina:1,num:12,value:"",categoriaId:it,clienteId:item.id}))
    }    
    
  return (   
        <div className="h-max border solid border-gray-300 text-[11px]">
            <button
                onClick={()=> handleSearch(0)}                
                className={sta === 0 ?'h-6 bg-slate-500 text-gray-50 pl-2 pr-2 border border-gray-200' :'h-6 bg-stone-100 text-gray-500 pl-2 pr-2 border border-gray-200'}>
                    Todos
            </button>
            { c_items.map((it,index)=>(
                <button
                onClick={()=> handleSearch(it.value)}
                key={index}
                className={sta === it.value ?'h-6 bg-slate-500 text-gray-50 pl-2 pr-2 border border-gray-200' :'h-6 bg-stone-100 text-gray-500 pl-2 pr-2 border border-gray-200'}>
                    {it.label}
                </button>
            ))}
        </div>          
    );
}

export default CateItems;