import {  useRef, useState } from 'react';
import { Link  } from 'react-router-dom'
import { apiUrl } from '../../herlpers/api'
import GoogleMapReact from 'google-map-react';
import {  useSelector } from "react-redux";
import { BuildingOfficeIcon, DocumentIcon, XMarkIcon, PhoneIcon, GlobeAltIcon } from "@heroicons/react/24/outline";
import { MapPinIcon } from "@heroicons/react/20/solid";


const ModalCliente = ({view,setview}) => {
    
    const { item, items} = useSelector((state) => state.clientes)                
    const [tab, settab] = useState('1');     

    const LocationPin = () =>(
        <MapPinIcon className="h-6 w-6 text-red-500" />
      )
    const mapRef = useRef()  

    
  
  return (
    <>
    { view ?
        <>
        <div className="justify-center items-center flex-1 fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-7 mx-auto max-w-3xl flex-row justify-between bg-gray-100">
            <div className='h-8 flex w-full  border-t border-b border-gray-300 justify-end'>
                <button
                    onClick={() => setview(false)}
                    className='w-7 flex h-7 rounded-full bg-red-400 hover:bg-red-300 justify-center items-center'>
                    <XMarkIcon className="h-5 w-5 text-gray-50" />
                </button> 
            </div>  
            <div className='w-full flex flex-col items-center justify-center p-1'>
                <div className='h-8 flex text-[11px] items-center w-full'>  
                    <button onClick={() => settab('1')} className={tab === '1'? "h-6 w-1/3 bg-sky-500 text-gray-50 flex items-center justify-center rounded-tl":"rounded-tl h-6 w-1/3  bg-gray-200 hover:bg-slate-300 text-gray-500 flex items-center justify-center"}>
                        Datos
                    </button>
                    <button onClick={() => settab('2')} className={tab === '2'? "h-6 w-1/3 bg-sky-500 text-gray-50 flex items-center justify-center rounded-tr":"rounded-tr h-6 w-1/3  bg-gray-200 hover:bg-slate-300 text-gray-500 border-gray-300 flex items-center justify-center"}>
                        Sucursales
                    </button>
                    <button onClick={() => settab('3')} className={tab === '3'? "h-6 w-1/3 bg-sky-500 text-gray-50 flex items-center justify-center rounded-tr":"rounded-tr h-6 w-1/3  bg-gray-200 hover:bg-slate-300 text-gray-500 border-gray-300 flex items-center justify-center"}>
                        Informacion
                    </button>                        
                </div>

                <div className="h-500 bg-white rounded-b shadow-lg relative flex w-full p-1 border border-gray-300">
                 <div className={tab === '1' ? "w-full visible h-490 flex flex-col ":"hidden"}>                     
                        <div className='w-full border border-gray-300 flex-col bg-white'>                                                                   
                            <div className="h-12 flex border-b">
                                <div className='w-2/12 flex items-center justify-center'>
                                    <img
                                    alt="img"
                                    className="h-10 w-10 rounded-full"
                                    src={`${apiUrl}static/images/clientes/lg/` + item.filename}
                                    /> 
                                </div>
                                <div className='w-8/12 flex items-center pl-2 text-[11px]'>
                                     <p>{item.nombres}</p>                                     
                                </div> 
                                <div className='w-2/12 flex items-center justify-center text-[11px]'>                                                                
                                    <Link to={item.slider} target="_blank" className='h-10 w-10 bg-sky-500 flex items-center justify-center rounded-md' >
                                        <DocumentIcon className="h-6 w-6 text-gray-50" />
                                    </Link>
                                </div>                                    
                            </div>
                            <div className="h-max text-[11px] text-gray-600  p-1">
                                <p className='w-full font-bold'>Direccion</p>
                                <p className='w-full border-b border-gray-200'>{item.direccion || "." }</p>

                                <div className='flex'>
                                    <p className='w-1/2 font-bold'>Categoria</p>
                                    <p className='w-1/2 font-bold'>Nit</p>                        
                                </div>

                                <div className='border-b border-gray-200 flex'>
                                    <p className='w-1/2 '>{item.categoria }</p>
                                    <p className='w-1/2 '>{item.nit}</p>                        
                                </div>
                                
                                <div className='flex'>
                                    <p className='w-1/2 font-bold'>Pais</p>
                                    <p className='w-1/2 font-bold'>Ciudad</p>                        
                                </div>

                                <div className='border-b border-gray-200 flex'>
                                    <p className='w-1/2 '>{item.pais}</p>
                                    <p className='w-1/2 '>{item.ciudad}</p>                        
                                </div>
                                <div className='flex'>
                                    <p className='w-1/2 font-bold'>Telefono</p>
                                    <p className='w-1/2 font-bold'>Celular</p>                        
                                </div>

                                <div className='border-b border-gray-200 flex'>
                                    <p className='w-1/2 '>{item.telefono|| "."}</p>
                                    <p className='w-1/2 '>{item.celular|| "."}</p>                        
                                </div>     
                                <div className='flex'>
                                    <p className='w-1/2 font-bold'>Web</p>
                                    <p className='w-1/2 font-bold'>E-mail</p>                        
                                </div>

                                <div className='border-b border-gray-200 flex'>
                                    <p className='w-1/2 '>{item.web}</p>
                                    <p className='w-1/2 '>{item.email}</p>                        
                                </div> 
                                <div className='flex'>
                                    <p className='w-1/3 font-bold'>Horario</p>
                                    <p className='w-1/3 font-bold'>Estado</p>                        
                                    <p className='w-1/3 font-bold'>Distancia</p>                        
                                </div>
                                <div className='border-b border-gray-200 flex'>
                                    <p className='w-1/2 '>{item.hinicio}-{item.hfin}</p>
                                    <p className='w-1/2 '>{item.estado}</p>                        
                                    <p className='w-1/2 '>{item.distancia} .km</p>                        
                                </div> 
                              

                            </div>
                         
                            <div className="h-12  text-[12px] p-1 justify-around flex">
                                <Link to={item.facebook} target="_blank"
                                className='h-8 w-8 rounded-full bg-stone-200 shadow-md font-bold  text-blue-500 flex items-center justify-center'>
                                    f
                                </Link>
                                <Link to={item.instagram} target="_blank"
                                className='h-8 w-8 rounded-full bg-stone-200 shadow-md font-bold text-pink-500 flex items-center justify-center'>
                                    i
                                </Link>
                                <Link to={item.tiktok} target="_blank"
                                className='h-8 w-8 rounded-full bg-stone-200 shadow-md font-bold text-gray-700 flex items-center justify-center'>
                                    t
                                </Link>
                                
                            </div>
                        </div>                      
                        <div className='h-200 w-full border border-gray-800'>
                            { item.latitude && item.longitude ?
                            <div className="h-full w-full">
                                <GoogleMapReact
                                ref={mapRef}
                                bootstrapURLKeys={{
                                    key: 'AIzaSyAF83DBU51q3idSspsd7f4DtTk7vNwHpR8',
                                    libraries:['places', 'geometry', 'drawing', 'visualization'] 
                                }}
                                defaultCenter={{
                                    lat: parseFloat(item.latitude),
                                    lng: parseFloat(item.longitude)
                                }}
                                defaultZoom={15}               
                                >
                                    <LocationPin
                                        lat= {parseFloat(item.latitude)}
                                        lng= {parseFloat(item.longitude)}
                                    />      
                                </GoogleMapReact>
                            </div>
                            :null
                            }
                        </div>
                    </div> 

                    <div className={tab === '2' ? "w-full visible h-490 bg-stone-100 flex flex-col ":"hidden"}>                     
                        <div className='w-full border border-gray-300 flex-col  overflow-auto p-1'>  
                        { items.map((ite,index)=> (
                                <div key={index}
                                 className="h-max w-full mb-1 p-1 flex flex-col text-[10px] bg-gray-200 float-left border border-gray-300 shadow-md">
                                    <div className='h-5 w-full border-b border-gray-300 flex text-gray-600'>                                                                             
                                        <p className='ml-1 w-full  border-slate-600 font-bold' >{ite.nombre}</p>                                        
                                    </div>
                                    <div className='h-5 w-full border-b border-gray-300 flex text-gray-600 items-center'>                                        
                                        <BuildingOfficeIcon className="h-5 w-5 text-gray-500" />
                                        <p className='ml-1 w-full'>{ite.direccion}</p>                                        
                                    </div>    
                                    <div className='h-5 w-full flex text-gray-600 items-center'>                                                                                
                                        <GlobeAltIcon className="h-4 w-4 text-gray-500" />
                                        <p className='ml-1 w-20'>{ite.ciudad}</p>                                        
                                        <PhoneIcon className="h-4 w-4 text-gray-500" />
                                        <p className='ml-1 w-20'>{ite.telefono}</p>
                                    </div>                                        

                                    

                                </div>                                                          
                            ))} 
                        </div>
                    </div>     

                    <div className={tab === '3' ? "w-full visible h-490 bg-stone-100 flex flex-col ":"hidden"}>                                             
                        <div className='h-max border w-full border-gray-300 text-[11px] text-gray-500'>
                            <p className='h-8 w-full bg-sky-500 font-bold pl-2 pt-2 text-gray-50'>Descripcion</p>
                            <p className='h-16 w-full pl-2 pt-2 border-gray-200 border'>
                                {item.descripcion || "." }
                            </p>
                        </div>                          
                    </div>     
                </div>
            
            
            </div>



          </div>   
        </div>
    <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
    : null  }
    </>        
    );
}

export default ModalCliente;