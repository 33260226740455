import { useSelector } from "react-redux";
import { apiUrl } from '../../herlpers/api'

const ProductosItems = () => {    
    const { _data} = useSelector((state) => state.clientes)                
 
  return (   
        <div className="h-460  bg-white solid text-[11px] w-full p-2">

            { _data.map((it,index)=>(
                <button
                key={index}
                className='h-40 w-1/6 bg-stone-100 rounded shadow-md  flex-col text-gray-500 border border-gray-200 m-1'>                    
                    <div className='h-5 w-full text-right flex text-gray-500 font-bold border-b text-[9px] items-center justify-end pr-2'>                        
                        <span>{new Intl.NumberFormat('es-BO',{minimumFractionDigits: 2}).format(it.precioVenta)}</span>
                    </div>
                    <div className='h-16 w-full bg-white flex items-center justify-center'>
                        <img alt=".."
                        className="h-16 w-18"          
                        src={`${apiUrl}static/images/productos/md/` + it.filename}
                        />
                    </div>
                    <div className='h-5 w-full bg-sky-500 text-gray-50 font-bold border-t border-sky-500 flex text-[9px] p-1 text-left text-ellipsis overflow-hidden'>
                        {it.categoria}
                    </div>
                    <div className='h-12  w-full border-t border-gray-300 flex bg-stone-100 text-[9px] p-1 text-left text-ellipsis overflow-hidden'>
                        {it.nombre}
                     
                    </div>                    
                </button>
            ))}
        </div>          
    );
}

export default ProductosItems;