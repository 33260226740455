import {  XCircleIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";

const Searchi = ({parametro,setParametro,handleSearch, handleParametro}) => {   

  const hReset = () =>{
      setParametro("")
      handleParametro()      
    }


  return (    
    <div className="h-7 flex text-[11px] w-full items-center ">   
    <div className='border border-gray-300 rounded flex w-full bg-white justify-end'>      
      <div className="w-full z-0">
        <form onSubmit={handleSearch} className="h-7 flex w-full items-center">                              
              <input
                type="text"
                name="parametro"
                value={parametro}                
                className="h-7 border-none border-r rounded-l rounded-r text-[11px] pl-4 w-full focus:ring-0 text-gray-500"
                onChange={(e)=> setParametro(e.target.value)}
              />
              <button                   
                className="h-5 w-6 border z-10 -ml-14 border-transparent text-[11px] font-medium rounded-full text-gray-700"
                type="button"
                onClick={()=>hReset()}>                    
                <XCircleIcon className={parametro ? "h-5 w-5 text-red-400":"h-5 w-5 text-white" }/>         
              </button> 
              <button                   
                className="h-5 w-6 z-10 border-none text-[11px] font-medium rounded-full text-gray-700">                    
                <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />          
              </button>
          </form>        
      </div>  
      </div>                      
    </div>        
  )
}

export default Searchi