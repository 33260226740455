import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";


import { jobsData } from '../../reducers/clienteSlice'
import Table from '@components/tables/TableJobs'
import Loading from '@components/snippets/Loading'

const OfertasInicio = () => {
  const dispatch = useDispatch()    
  const { loading, jobs } = useSelector((state) => state.clientes) 
  const xciudad  = JSON.parse(localStorage.getItem('@xciudad')) 


  useEffect(() => {
    dispatch(jobsData({ciudad:xciudad}))
    return () => {
      
    };
  }, []);


  return (    
    <>                         
    <div className='h-max flex'>  
    <div className='h-370 w-full flex flex-col border rounded bg-slate-600  border-gray-600 p-2'>
        <Table  data={jobs}/>
    </div>                                
  </div>  
  <Loading loading={loading}/>
  </> 
  );
}

export default OfertasInicio;
