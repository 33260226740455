import { configureStore} from '@reduxjs/toolkit'
import { reducer as toastrReducer} from 'react-redux-toastr'
import clientesReducer from './bundles/reducers/clienteSlice'
import cateReducer from './bundles/reducers/cateSlice'
import categoriaReducer from './bundles/reducers/categoriaSlice'
import tpdvReducer from './bundles/reducers/tpdvSlice'

const reducer ={      
    clientes    : clientesReducer,   
    tpdv        : tpdvReducer,
    categoria   : categoriaReducer, 
    cate        : cateReducer,
    toastr      : toastrReducer
    
}

export const store = configureStore({
    reducer: reducer,
    devTools: true,
})