export const getCiudades = (paisId) =>{
    return _ciudades.filter(item => 
       item.indice === paisId
  )}



  export const _ciudades= [
    {"label":"Buenos Aires","value":"Buenos Aires","indice":0,"latitude":-34.603684,"longitude":-58.381559},    
    {"label":"Cordoba","value":"Cordoba","indice":0,"latitude":-31.416668,"longitude":-64.183334},    
    {"label":"Corrientes","value":"Corrientes","indice":0,"latitude":-27.471226,"longitude":-58.839584},    
    {"label":"Mar del Plata","value":"Mar del Plata","indice":0,"latitude":-37.979858,"longitude":-57.589794},
    {"label":"Rosario","value":"Rosario","indice":0,"latitude":-32.950001,"longitude":-60.666668},
    {"label":"San Miguel de Tucuman","value":"San Miguel de Tucuman","indice":0,"latitude":-26.808285,"longitude":-65.217590},
    {"label":"Santa Fe","value":"Santa Fe","indice":0,"latitude":-31.64881,"longitude":-60.70868},
    {"label":"Salta","value":"Salta","indice":0,"latitude":-24.782932,"longitude":-65.412155},
    
/*    {"label":"Beni","value":"Beni","indice":1,"latitude":-17.78629,"longitude":-63.18117},*/
    {"label":"Beni","value":"Beni","indice":1,"latitude":-14.8334,"longitude":-64.896806},	  
    {"label":"Cochabamba","value":"Cochabamba","indice":1,"latitude":-17.3895,"longitude":-66.1568},
    {"label":"La Paz","value":"La Paz","indice":1,"latitude":-16.5,"longitude":-68.15},
    {"label":"Oruro","value":"Oruro","indice":1,"latitude":-17.98333,"longitude":-67.15},
    {"label":"Pando","value":"Pando","indice":1,"latitude":-11.02671,"longitude":-68.76918},
    {"label":"Potosi","value":"Potosi","indice":1,"latitude":-19.58361,"longitude":-65.75306},        
    {"label":"Santa Cruz","value":"Santa Cruz","indice":1,"latitude":-17.78629,"longitude":-63.18117},
    {"label":"Sucre","value":"Sucre","indice":1,"latitude":-19.03332,"longitude":-65.26274},
    {"label":"Tarija","value":"Tarija","indice":1,"latitude":-21.53549,"longitude":-64.72956},
    
    {"label":"Antofagasta","value":"Antofagasta","indice":2,"latitude":-23.65236,"longitude":-70.3954},
    {"label":"Concepción","value":"Concepción","indice":2,"latitude":-36.82699,"longitude":-73.04977},    
    {"label":"Iquique","value":"Iquique","indice":2,"latitude":-20.21326,"longitude":-70.15027},
    {"label":"La Serena","value":"La Serena","indice":2,"latitude":-29.90453,"longitude":-71.24894},
    {"label":"Puerto Montt","value":"Puerto Montt","indice":2,"latitude":-41.4693,"longitude":-72.94237},  
    {"label":"Santiago","value":"Santiago","indice":2,"latitude":-33.45694,"longitude":-70.64827},
    
    
    {"label":"Barranquilla","value":"Barranquilla","indice":3,"latitude":10.96854,"longitude":-74.78132},
    {"label":"Bogota","value":"Bogota","indice":3,"latitude":4.60971,"longitude":-74.08175},
    {"label":"Cali","value":"Cali","indice":3,"latitude":3.43722,"longitude":-76.5225},
    {"label":"Cartagena","value":"Cartagena","indice":3,"latitude":10.39972,"longitude":-75.51444},
    {"label":"Medellin","value":"Medellin","indice":3,"latitude":6.25184,"longitude":-75.56359},  
    
    {"label":"San Jose","value":"San Jose","indice":4,"latitude":9.9325,"longitude":-84.0800},         
    {"label":"Alajuela","value":"Alajuela","indice":4,"latitude":10.1640,"longitude":-84.2645},     
       
    
    {"label":"Santa Tecla","value":"Santa Tecla","indice":5,"latitude":13.67694,"longitude":-89.27972},     
    {"label":"Santa Ana","value":"Santa Ana","indice":5,"latitude":13.99417,"longitude":-89.55972},         
    {"label":"San Salvador","value":"San Salvador","indice":5,"latitude":13.68935,"longitude":-89.18718},     
    {"label":"San Miguel","value":"San Miguel","indice":5,"latitude":13.48333,"longitude":-88.18333},   
    {"label":"Soyapango","value":"Soyapango","indice":5,"latitude":13.71024,"longitude":-89.13989},    
    
    {"label":"Guadalajara","value":"Guadalajara","indice":6,"latitude":20.6767,"longitude":-103.3475},     
    {"label":"Juárez","value":"Juárez","indice":6,"latitude":31.7386,"longitude":-106.4870},     
    {"label":"León","value":"León","indice":6,"latitude":21.1167,"longitude":-101.6833},     
    {"label":"Monterrey","value":"Monterrey","indice":6,"latitude":25.6667,"longitude":-100.3000},     
    {"label":"Puebla","value":"Puebla","indice":6,"latitude":19.0333,"longitude":-98.1833},     
    {"label":"Tijuana","value":"Tijuana","indice":6,"latitude":32.5250,"longitude":-117.0333},
    
    {"label":"Managua","value":"Managua","indice":7,"latitude":12.13282,"longitude":-86.2504},  
    {"label":"León","value":"León","indice":7,"latitude":12.43787,"longitude":-86.87804},  
    {"label":"Masaya","value":"Masaya","indice":7,"latitude":11.97444,"longitude":-86.09417},  
    {"label":"Matagalpa","value":"Matagalpa","indice":7,"latitude":12.92559,"longitude":-85.91747},  
    
    {"label":"Asunción","value":"Asunción","indice":8,"latitude":-25.28646,"longitude":-57.647},  
    {"label":"Ciudad del Este","value":"Ciudad del Este","indice":8,"latitude":-25.50972,"longitude":-54.61111},    
    
    {"label":"Arequipa","value":"Arequipa","indice":9,"latitude":-16.39889,"longitude":-71.535},
    {"label":"Ayacucho","value":"Ayacucho","indice":9,"latitude":-13.15878,"longitude":-74.22321},
    {"label":"Juliaca","value":"Juliaca","indice":9,"latitude":-15.5,"longitude":-70.13333},
    {"label":"Lima","value":"Lima","indice":9,"latitude":-12.04318,"longitude":-77.02824},
    {"label":"Trujillo","value":"Trujillo","indice":9,"latitude":-8.11599,"longitude":-79.02998},
    
    {"label":"La Romana","value":"La Romana","indice":10,"latitude":18.42733,"longitude":-68.97285},
    {"label":"Punta Cana","value":"Punta Cana","indice":10,"latitude":19.79344,"longitude":-70.6884},  
    {"label":"Santo Domingo","value":"Santo Domingo","indice":10,"latitude":18.47186,"longitude":-69.89232},
    
    {"label":"Canelones","value":"Canelones","indice":11,"latitude":-34.5167,"longitude":-56.2833},
    {"label":"Colonia","value":"Colonia","indice":11,"latitude":-34.4714,"longitude":-57.8442},
    {"label":"Maldonado","value":"Maldonado","indice":11,"latitude":-34.9000,"longitude":-54.9500},
    {"label":"Montevideo","value":"Montevideo","indice":11,"latitude":-34.8836,"longitude":-56.1819},
    
    {"label":"Caracas","value":"Caracas","indice":12,"latitude":10.4806,"longitude":-66.9036},        
    {"label":"Maracay","value":"Maracay","indice":12,"latitude":10.2469,"longitude":-67.5958},
    {"label":"Maracaibo","value":"Maracaibo","indice":12,"latitude":10.6333,"longitude":-71.6333},
    {"label":"Valencia","value":"Valencia","indice":12,"latitude":10.1667,"longitude":-68.0000},
    ]
