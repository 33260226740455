import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {toastr} from 'react-redux-toastr'
import serviceCrm  from "../services/serviceCrm"
/*import { setCategorias } from "@inventario/reducers/categoriaSlice"*/
/*import { setProductos } from "@reducers/inventario/productoSlice"*/
/*import { setItem } from './cajasSlice'*/

export const tpdvsData = createAsyncThunk(
    "tpdvs/data",
    async(dato,thunkAPI)=>{
        try{                    
          const data = await serviceCrm._data(dato,'tpdvs');   
              
          /*thunkAPI.dispatch(setCategorias(data.categorias))*/
          /*thunkAPI.dispatch(setProductos(data.productos))
          thunkAPI.dispatch(setItem(data.caja))                                              */
            return { response: data }
        }catch(error){                  
            return thunkAPI.rejectWithValue();           
        }   
    }
)
export const tpdvsCreate = createAsyncThunk(
  "tpdvs/create",
  async(dato,thunkAPI)=>{
      try{      
          const data = await serviceCrm._create(dato,'tpdvs');     
          /*thunkAPI.dispatch(setProductos(data.stocks))
          thunkAPI.dispatch(setItem(data.caja))  */
          toastr.success('tpdv', 'Dato creado')           
          return { response: data }
      }catch(error){                  
          return thunkAPI.rejectWithValue();           
      }   
  }
)

export const tpdvsUpdate = createAsyncThunk(
  "tpdvs/update",
  async(dato,thunkAPI)=>{
      try{      
          const data = await serviceCrm._update(dato,'tpdvs');  
          toastr.success('tpdv', 'Actualizada')                                        
          return { response: data }
      }catch(error){                  
          return thunkAPI.rejectWithValue();           
      }   
  }
)

export const tpdvsItem = createAsyncThunk(
  "tpdvs/item",
  async(pky,thunkAPI)=>{    
      try{      
          const item = await serviceCrm._item(pky,'tpdvs');                                       
          return { response: item }
      }catch(error){                  
          return thunkAPI.rejectWithValue();           
      }   
  }
)




export const tpdvsDelete = createAsyncThunk(
  "tpdvs/delete",
  async(dato,thunkAPI)=>{
      try{      
          const data = await serviceCrm._delete(dato,'tpdvs');    
          toastr.warning('tpdv', 'cotización eliminada')                                  
          return { response: data }
      }catch(error){            
        let rr = error.response.data.message        
        toastr.error('Cotización', rr.message) 
        return thunkAPI.rejectWithValue();           
      }   
  }
)


export const tpdvsAprobar = createAsyncThunk(
  "tpdvs/aprobar",
  async(dato,thunkAPI)=>{
      try{      
          const data = await serviceCrm._aprobar(dato,'tpdvs');                                      
          toastr.success('tpdv', 'Dato creado') 
          return { response: data }
      }catch(error){                  
          return thunkAPI.rejectWithValue();           
      }   
  }
)

export const tpdvsItems = createAsyncThunk(
  "tpdvs/items",
  async(dato,thunkAPI)=>{
      try{      
          const data = await serviceCrm._searchItems(dato,'tpdvs');                                      
         
          return { response: data }
      }catch(error){                  
          return thunkAPI.rejectWithValue();           
      }   
  }
)




const initialState = { 
  loading:false,
  data:[],
  vitems:[],
  items:[],
  nota:{},
  plan:[],
  total:0,
  pagina:0,
  paginas:0,
  item:{    
    cantidad:0,    
    nroItems:0,
    subTotal:0,
    descuento:0,
    totalGeneral:0,
    clienteId:0,
    ncliente:"",
    nit:"",
  },    
  parametro:-1
};  


const tpdvSlice = createSlice({
  name: "tpdv",
  initialState,  
  reducers:{
    setItems:(state,action)=>{
      state.vitems = action.payload
    },    
    setParametro:(state,action)=>{
      state.parametro = action.payload.parametro
      state.cantidad  = action.payload.cantidad
    },    
    setItem:(state,action)=>{
      state.item = action.payload
    },
    resetItem:(state)=>{
      state.item ={
        clienteId:0,
        ncliente:"",
        nit:"",    
        cantidad:0,    
        subTotal:0,
        descuento:0,
        totalGeneral:0,        
      }
      state.vitems  = []  
      state.items   = []        
      state.nota    = {}
      state.plan    = []
    },
    resetData:(state)=>{
      state.data    = []  
      state.total   = 0
      state.pagina  = 0
      state.paginas = 0   
      state.vitems  = []  
      state.items   = []        
      state.nota    = {}
      state.plan    = []
    },  
    setCliente:(state,action)=>{
      state.item.clienteId  = action.payload.clienteId
      state.item.clients    = action.payload.clients      
    },
    setClienteId:(state,action)=>{
      state.clienteId  = action.payload.clienteId
      state.ncliente   = action.payload.ncliente
      state.nit        = action.payload.nit
    },  
  },
  extraReducers(builder) { 
    builder
      .addCase(tpdvsItems.pending,(state) =>{
        state.loading = true                    
      })
      .addCase(tpdvsItems.fulfilled,(state,action)=>{
          state.loading = false          
          state.items  = action.payload.response          
      })
      .addCase(tpdvsItems.rejected,(state)=>{
        state.loading = false        
        state.items    = []
      })

      .addCase(tpdvsItem.pending,(state) =>{
        state.loading = true            
        state.item    = {}
      })
      .addCase(tpdvsItem.fulfilled,(state,action)=>{
          state.loading = false
          state.item      = action.payload.response.item              
          state.vitems    = action.payload.response.items
          state.nota      = action.payload.response.nota
          state.plan      = action.payload.response.plan
      })
      .addCase(tpdvsItem.rejected,(state)=>{
        state.loading = false
        state.item      = {}
        state.vitems    = []
        state.nota      = {}
        state.plan      = []
      })
      /** create */
      .addCase(tpdvsCreate.pending,(state) =>{
        state.loading = true                    
      })
      .addCase(tpdvsCreate.fulfilled,(state,action)=>{
          state.loading = false
          state.data  = action.payload.response.data    
          state.total = action.payload.response.total          
          state.pagina = action.payload.response.pagina          
          state.paginas = action.payload.response.paginas           
      })
      .addCase(tpdvsCreate.rejected,(state)=>{
        state.loading = false        
      })

      /** aprobar */
      .addCase(tpdvsAprobar.pending,(state) =>{
        state.loading = true                    
      })
      .addCase(tpdvsAprobar.fulfilled,(state,action)=>{
          state.loading = false
          state.data  = action.payload.response.data    
          state.total = action.payload.response.total          
          state.pagina = action.payload.response.pagina          
          state.paginas = action.payload.response.paginas           
      })
      .addCase(tpdvsAprobar.rejected,(state)=>{
        state.loading = false        
      })
      /** update */
      .addCase(tpdvsUpdate.pending,(state) =>{
        state.loading = true                    
      })
      .addCase(tpdvsUpdate.fulfilled,(state,action)=>{
          state.loading = false
          state.item    = action.payload.response.item
          state.vitems  = action.payload.response.items          
      })
      .addCase(tpdvsUpdate.rejected,(state)=>{
        state.loading = false
        state.item      = {}
        state.vitems    = []
      })

      .addCase(tpdvsDelete.pending,(state) =>{
        state.loading = true            
      })
      .addCase(tpdvsDelete.fulfilled,(state,action)=>{
          state.loading = false
          state.data  = action.payload.response.data    
          state.total = action.payload.response.total          
          state.pagina = action.payload.response.pagina          
          state.paginas = action.payload.response.paginas          
      })
      .addCase(tpdvsDelete.rejected,(state)=>{
        state.loading = false        
      }) 

     
  }    
});
export const { resetItem, resetData, setParametro, setItems, setItem  } = tpdvSlice.actions
const { reducer } = tpdvSlice;
export default reducer;  
