import {  useEffect, useState } from 'react';
import { Link  } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { BuildingOfficeIcon, DocumentIcon, PrinterIcon, EyeIcon, XMarkIcon, HandThumbUpIcon, PhoneIcon, GlobeAltIcon } from "@heroicons/react/24/outline";
import CateItems from './CateItems'
import ProductosItems from './ProductosItems'

const ModalPay = ({view,setview}) => {     
    const dispatch = useDispatch() 
   
                  
  return (
    <>
    { view ?
        <>
        <div className="justify-center items-center flex-1 fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-7 mx-auto max-w-3xl flex-row justify-between">
                <div className='h-8 flex w-full  border-t border-b border-gray-300 bg-white'>
                    <div className='w-3/12 flex pl-1'>  
                        <button
                        onClick={() => setview(false)}
                        className='w-7 flex h-7 rounded-full bg-red-400 hover:bg-red-300 justify-center items-center'>
                            <XMarkIcon className="h-5 w-5 text-gray-50" />
                        </button>              
                    </div>   
                    <div className='w-9/12 flex items-end pr-1 justify-end'>
                        <div className='h-8 flex text-[11px] items-end'>  
                            <button className="h-6 w-32 bg-sky-500 text-gray-50 flex items-center justify-center rounded-tl">
                                Productos
                            </button>                                                 
                        </div>
                    </div>                                           
                </div>
                <div className="h-500  bg-white rounded-b shadow-lg relative flex flex-col w-full outline-none focus:outline-none p-1">
                    <div className="w-full visible h-8 flex border-b">                     
                       <CateItems/>
                    </div>
                    <div className="w-full visible h-480 flex border-2">                     
                       <ProductosItems/> 
                    </div>      
                </div>  
            </div>  
        </div>
    <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
    : null  }
    </>        
    );
}

export default ModalPay;