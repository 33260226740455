import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { productosData  } from '../../reducers/clienteSlice'

import { setItems, setItem, resetItem} from '../../reducers/tpdvSlice'
import { apiUrl } from '../../herlpers/api'
import {toastr} from 'react-redux-toastr'
import Loading from '@components/snippets/Loading'
import { TagIcon, MagnifyingGlassIcon ,QrCodeIcon } from "@heroicons/react/24/outline";

const VentaProductos = () => {
    const dispatch = useDispatch()      
    const { _data, loading } = useSelector((state) => state.clientes)
    const { vitems, item } = useSelector((state) => state.tpdv) 
    const [parametro, setparametro] = useState("");     
    const [prop, setprop] = useState(false);     
    let ep  = JSON.parse(localStorage.getItem('@xmoneda'))     
    const [view, setview] = useState(false); 
    const d = new Date()
    const gestion = d.getFullYear()
    const mes = d.getMonth()+1
       

  
        
    const asignar = (itt)=>{                 
        let nItems = [...vitems]  
        let nvalor  = itt.tipo === "simple" ? itt.precioUnidad : itt.precioVenta
        let nunidad = itt.tipo === "simple" ? 'unidad' : itt.unidad        
        let result = nItems.findIndex(item => item.id === itt.id);        
        if(result < 0){
            const iok = {
                id         : itt.id,
                productoId : itt.productoId,
                nombre     : itt.nombre,
                valor      : nvalor,
                tipo       : itt.tipo,
                cantidad   : 1,
                subTotal   : nvalor,     
                precioUnidad : itt.precioUnidad,           
                gestion    : gestion,
                mes        : mes,
                unidad     : nunidad,
                stock      : itt.stock

            }            
            nItems.push(iok)            
            dispatch(setItems(nItems))            

            let nc = 0
            nc = item.cantidad ? parseFloat(item.cantidad) + parseFloat(1): 1
            /*dispatch(setCantidad(nc))*/

            let nn = 0
            nn = item.totalGeneral ? parseFloat(item.totalGeneral) + parseFloat(nvalor) : parseFloat(nvalor)
            let ion= {...item}
            ion.cantidad     = nc
            ion.subTotal     = nn
            ion.totalGeneral = nn
            dispatch(setItem(ion))
            descontar(itt.id,itt.stock)

        }else{
            let va = parseInt(vitems[result].stock)
            let vb = parseInt(vitems[result].cantidad)            
            
            if(vb < va){
            let newCantidad = parseInt(vitems[result].cantidad) + 1
            let newTotal    = parseFloat(vitems[result].subTotal) + parseFloat(vitems[result].valor)  
            

            const newArr = vitems.map((obj, key) => {
            if (key === result) {
                return {...obj, cantidad: newCantidad, subTotal: newTotal};
                }          
                return obj;
            });

            dispatch(setItems(newArr))     
            /*let nc = parseInt(item.nroItems) + 1*/
            /*dispatch(setCantidad(nc))*/
            let nc = parseInt(item.cantidad) + 1
            let nn = parseFloat(item.totalGeneral) + parseFloat(vitems[result].valor)
            /*dispatch(setTotal(nn))*/
            let ion= {...item}
            ion.cantidad     = nc
            ion.subTotal     = nn
            ion.totalGeneral = nn
            dispatch(setItem(ion))
            descontar(itt.id)
            }else{
                toastr.error('Stock', 'No existe Stock') 
            }
        }        
        
    }    

   useEffect(() => {
        
        return () => {
            /*dispatch(resetItem())*/
        };
    }, []);

            
   
    const descontar = (id) =>{
        let resu = _data.findIndex(item => item.id === id);          
        let nn = [..._data]        
        let newProductos = nn.map((tt,index)=>{                       
            let nn = tt.stock
            if(index === resu){
                nn = nn - 1 
            }
            let iok = {
                categoria   : tt.categoria,
                codigo      : tt.codigo,
                filename    : tt.filename,
                id          : tt.id,
                tipo        : tt.tipo,
                productoId  : tt.productoId,
                marca       : tt.marca,
                nombre      : tt.nombre,
                precioVenta : tt.precioVenta,        
                precioUnidad : tt.precioUnidad,        
                stock       : nn,
                unidad      : tt.unidad
            }
            return iok
        })                 
      /*  dispatch(setLista(newProductos))*/
    }



    const handleClick = (e,pky) => {
        e.preventDefault();
        if (e.type === 'contextmenu') {            
            let iok ={
                  id : pky,
                  tip: "list"
            }
           /* dispatch(productosItem(iok))*/
            setview(true)
        }
    };

    return (
        <>
        <div className="h-max w-full">                  
           <div className='h-555 w-full p-2 overflow-auto'>
           { _data &&        
                _data.map((itt,index)=>(
                    <button 
                        key={index}                          
                        onClick={()=> asignar(itt)}
                        onContextMenu={(e)=>handleClick(e,itt.productoId)}
                        className={itt.stock > 0 ?"w-24 bg-stone-100 border border-gray-200  rounded shadow-md hover:shadow-md  hover:border-sky-400 flex-col float-left mr-2 mb-2":"w-24 rounded flex-col float-left mr-2 mb-2 opacity-50 cursor-not-allowed"}>             
                        <div className='h-max'>                            
                            <div className="h-14 text-[11px] text-stone-500 flex justify-center items-center p-1">
                               <img
                                alt="producto"
                                className="h-[50px] w-[67px] z-10"
                                src={`${apiUrl}static/images/productos/sm/` + itt.filename}                            
                                />
                            </div>
                            <div className="h-4 w-full text-[9px] items-center flex">
                                <span className={itt.tipo === "simple" ? 'h-max w-20 text-gray-50 bg-red-400 flex justify-center items-center':'h-max w-5/6 text-gray-50 font-bold border-t border-b border-r bg-slate-500 border-gray-300 flex justify-center items-center'}>
                                {itt.tipo === "simple" ? new Intl.NumberFormat('es-'+ep.moneda,{minimumFractionDigits: 2}).format(itt.precioUnidad || "0") : new Intl.NumberFormat('es-'+ep.moneda,{minimumFractionDigits: 2}).format(itt.precioVenta || "0")} 
                                </span>                                                
                            </div>                                              
                            <div className="h-8 text-[9px] text-stone-500 text-ellipsis overflow-hidden ... text-left flex font-bold w-full pl-1 pr-2 pt-1">                                    
                                {itt.nombre}                                                
                            </div>  
                            
                        </div>                                               
                    </button>
                ))
            }        
           </div>          
        </div>
        
        <Loading loading={loading}/>
        </>
    );
}

export default VentaProductos;
