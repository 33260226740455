import axios from 'axios'
import { apiUrl } from '../herlpers/api'
import authHeader  from './auth-header.js'


const _data = async (dato, endpoint) => {    
    const response = await axios
      .post(apiUrl + `${endpoint}/data/list`, dato, {
        headers: { 'Access-Control-Allow-Origin': '*', "Content-Type": "application/json" },
      });   
    return response.data.result
};

const _dataList = async (dato, endpoint) => {    
  const response = await axios
    .post(apiUrl + `${endpoint}/data/list/jobs`, dato, {
      headers: { 'Access-Control-Allow-Origin': '*', "Content-Type": "application/json" },
    });   
  return response.data.result
};

const _create = async (dato, endpoint) => {    
  const response = await axios
    .post(apiUrl + `${endpoint}/unit`, dato, {
      headers: { ...authHeader(), "Content-Type": "application/json" },
    });    

  return response.data.result
};

const _update = async (dato, endpoint) => {            
  const response = await axios
    .put(apiUrl + `${endpoint}/${dato.id}/${dato.tip}`, dato, {
      headers: { ...authHeader(), "Content-Type": "application/json" },
    });         
  return response.data.result    
};

const _item = async (dato, endpoint) => {    
  const response = await axios
    .post(apiUrl + `${endpoint}/item/${dato.id}/unit`,dato, {
      headers: { ...authHeader(), "Content-Type": "application/json" },
    });    
  return response.data.result
};

const _titem = async (dato, endpoint) => {    
  const response = await axios
    .get(apiUrl + `${endpoint}/item/${dato.id}/unit`,dato, {
      headers: { ...authHeader(), "Content-Type": "application/json" },
    });    
  return response.data.result
};

const _itemsList = async (dato,endpoint) => {        
  
  const response = await axios
  .post(apiUrl + `${endpoint}/listas/items`, dato, {        
    headers: { ...authHeader(), "Content-Type": "application/json" },
  });    
return response.data.result
};

const _items = async (endpoint) => {        
  
  const response = await axios
  .get(apiUrl + `${endpoint}/listas/items`, {        
    headers: { ...authHeader(), "Content-Type": "application/json" },
  });    
return response.data.result
};

const _litems = async (dato,endpoint) => {        
  
  const response = await axios
  .get(apiUrl + `${endpoint}/listas/items/${dato.pky}`, {        
    headers: { ...authHeader(), "Content-Type": "application/json" },
  });    
return response.data.result
};

const _informes = async (dato, endpoint) => {    
  const response = await axios
    .post(apiUrl + `informes/${endpoint}`, dato, {
      headers: { ...authHeader(), "Content-Type": "application/json" },
    });    

  return response.data.result
};

const _delete = async (dato, endpoint) => {    
  const response = await axios
    .post(apiUrl + `${endpoint}/delete/item/list`, dato, {
      headers: { ...authHeader(), "Content-Type": "application/json" },
    });
  return response.data.result
};

const _search = async (dato, endpoint) => {    
  const response = await axios
    .post(apiUrl + `${endpoint}/search/stock`, dato, {
      headers: { ...authHeader(), "Content-Type": "application/json" },
    });
  return response.data.result
};


const crmService = {                
    _data,
    _dataList,
    _create,
    _update,
    _delete,
    _item,
    _titem,
    _itemsList,
    _search,
    _items,
    _litems,
    _informes    
};
    
export default crmService;
