import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { setItems, setParametro, setItem } from '../../reducers/tpdvSlice'
import { productosData  } from '../../reducers/clienteSlice'
import {  TrashIcon, CurrencyDollarIcon } from "@heroicons/react/24/outline";

const Calculo = ({setview}) => {
    const dispatch = useDispatch()              
    const { item } = useSelector((state) => state.clientes) 

        
    const handelDelete = (itt)=>{
        let nItems = []
            dispatch(setItems(nItems))            
            let nc = 0            
            /*dispatch(setCantidad(nc))*/
            let nn = 0            
            /*dispatch(setTotal(nn))     */
            let ion= {...item}
            ion.cantidad     = nc
            ion.subTotal     = nn
            ion.totalGeneral = nn
            dispatch(setItem(ion))
            let iok={
                cantidad:0,
                parametro:-1
            }
            dispatch(setParametro(iok))
            /*getProductos()  */
    }
    
 

    const openCaja = () =>{
    
        if(item){
            setview(true)
        }        
    }



    useEffect(() => {
       
        return () => {
            
        };
    }, []);

   

   return (
    <div className="h-12 w-full flex items-center p-1 bg-stone-100">             
        <button onClick={() => openCaja()} 
            className='h-10 w-1/2 bg-slate-500 rounded-l flex items-center justify-center '>
            <CurrencyDollarIcon className="h-5 text-gray-50" />
         </button>
        <button onClick={() => handelDelete()}
            className='h-10 w-1/2 bg-red-400 rounded-r flex items-center justify-center'>
            <TrashIcon className="h-5 text-gray-50" />   
        </button>                   
    </div>
    );
}

export default Calculo;
