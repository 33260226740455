import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Layout}  from '@layouts'
import ReduxToastr from 'react-redux-toastr'
import './assets/core/main.css';
import './assets/daygrid/main.css';
import './assets/timegrid/main.css'

const App = () => {  
  return (
    <>
      <ReduxToastr
      timeOut={1500}
      newestOnTop={false}
      preventDuplicates
      position="top-right"
      getState={(state) => state.toastr} 
      transitionIn= 'bounceIn'
      transitionOut= 'bounceOut'
      progressBar= {true}
      closeOnToastrClick/> 
      <Routes>                
        <Route index element={<Layout />} /> 
        <Route path="/*" element={<Layout/> } />                                                   
      </Routes>      
      </>    
  );
};

export default App;