import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {toastr} from 'react-redux-toastr'
import serviceCrm  from "../services/serviceCrm"

export const categoriasData = createAsyncThunk(
  "categoria/data",
  async(dato,thunkAPI)=>{
      try{                    
        const data = await serviceCrm._data(dato,'categorias'); 
                                              
          return { response: data }
      }catch(error){                  
          return thunkAPI.rejectWithValue();           
      }   
  }
)
export const categoriasUpdate = createAsyncThunk(
  "categoria/update",
  async(dato,thunkAPI)=>{
      try{      
          const data = await serviceCrm._update(dato,'categorias');  
          toastr.success('Categoría', 'Actualizada')                                       
          return { response: data }
      }catch(error){                  
          return thunkAPI.rejectWithValue();           
      }   
  }
)

export const categoriasCreate = createAsyncThunk(
  "categoria/create",
  async(dato,thunkAPI)=>{
      try{      
          const data = await serviceCrm._create(dato,'categorias');                                      
          toastr.success('Categoría', 'Dato creado') 
          return { response: data }
      }catch(error){                
          toastr.error('Categoría', 'Nombre existente')           
          return thunkAPI.rejectWithValue();           
      }   
  }
)

export const categoriasDelete = createAsyncThunk(
  "categoria/delete",
  async(dato,thunkAPI)=>{
      try{      
          const data = await serviceCrm._delete(dato,'categorias');    
          toastr.warning('Categoría', 'categoria eliminada')                                  
          return { response: data }
      }catch(error){            
        let rr = error.response.data.message        
        toastr.error('Categoria', rr.message) 
        return thunkAPI.rejectWithValue();           
      }   
  }
)

export const categoriasItems = createAsyncThunk(
  "categoria/items",
  async(thunkAPI)=>{
      try{                    
        const data = await serviceCrm._items('categorias');                                               
          return { response: data }
      }catch(error){                  
          return thunkAPI.rejectWithValue();           
      }   
  }
)



const initialState = { 
    loading:false,
    data:[],
    c_items:[],
    total:0,
    pagina:0,
    paginas:0,
    item:{},    
}; 


const categoriaSlice = createSlice({
    name: "categoria",
    initialState,  
    reducers:{
      setCategorias:(state,action)=>{      
        state.c_items = action.payload
      },
      resetItem:(state)=>{
        state.item ={}
      },
      resetData:(state)=>{
        state.data    = []  
        state.total   = 0
        state.pagina  = 0
        state.paginas = 0   
      },    
    },
    extraReducers(builder) { 
      builder
      
      .addCase(categoriasItems.pending,(state) =>{
        state.loading = true            
      })
      .addCase(categoriasItems.fulfilled,(state,action)=>{
          state.loading = false
          state.c_items  = action.payload.response
      })
      .addCase(categoriasItems.rejected,(state)=>{
        state.loading = false
      
      })


      .addCase(categoriasData.pending,(state) =>{
        state.loading = true            
      })
      .addCase(categoriasData.fulfilled,(state,action)=>{
          state.loading = false
          state.data  = action.payload.response.data    
          state.total = action.payload.response.total          
          state.pagina = action.payload.response.pagina          
          state.paginas = action.payload.response.paginas          
      })
      .addCase(categoriasData.rejected,(state)=>{
        state.loading = false
      
      }) 
      .addCase(categoriasCreate.pending,(state) =>{
        state.loading = true            
        state.item    = {}
      })
      .addCase(categoriasCreate.fulfilled,(state,action)=>{
          state.loading = false
          state.data  = action.payload.response.data    
          state.total = action.payload.response.total          
          state.pagina = action.payload.response.pagina          
          state.paginas = action.payload.response.paginas            
      })
      .addCase(categoriasCreate.rejected,(state)=>{
        state.loading = false
        state.item    = {}
      })
      /** update */
      .addCase(categoriasUpdate.pending,(state) =>{
        state.loading = true            
        state.item    = {}
      })
      .addCase(categoriasUpdate.fulfilled,(state,action)=>{
        state.loading = false
        state.data  = action.payload.response.data    
        state.total = action.payload.response.total          
        state.pagina = action.payload.response.pagina          
        state.paginas = action.payload.response.paginas              
      })
      .addCase(categoriasUpdate.rejected,(state)=>{
        state.loading = false
        state.item    = {}
      })
      .addCase(categoriasDelete.pending,(state) =>{
        state.loading = true            
      })
      .addCase(categoriasDelete.fulfilled,(state,action)=>{
          state.loading = false
          state.data  = action.payload.response.data    
          state.total = action.payload.response.total          
          state.pagina = action.payload.response.pagina          
          state.paginas = action.payload.response.paginas          
      })
      .addCase(categoriasDelete.rejected,(state)=>{
        state.loading = false        
      }) 
    }   
  });
  export const { resetItem, resetData, setCategorias } = categoriaSlice.actions
  const { reducer } = categoriaSlice;
  export default reducer;  
  