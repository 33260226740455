import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {toastr} from 'react-redux-toastr'
import serviceCrm  from "../services/serviceCrm"

export const productosData = createAsyncThunk(
  "productos/data",
  async(dato,thunkAPI)=>{
      try{                    
        const data = await serviceCrm._search(dato,'stock');                                                
        return { response: data }
      }catch(error){                  
          return thunkAPI.rejectWithValue();           
      }   
  }
)

export const jobsData = createAsyncThunk(
  "jobs/data",
  async(dato,thunkAPI)=>{
      try{                    
        const data = await serviceCrm._dataList(dato,'consultas');                                                
        return { response: data }
      }catch(error){                  
          return thunkAPI.rejectWithValue();           
      }   
  }
)


export const clientesData = createAsyncThunk(
  "clientes/data",
  async(dato,thunkAPI)=>{
      try{                    
        const data = await serviceCrm._data(dato,'consultas');   
                                                   
        return { response: data }
      }catch(error){                  
          return thunkAPI.rejectWithValue();           
      }   
  }
)

export const clientesItems = createAsyncThunk(
  "clientes/items",
  async(dato,thunkAPI)=>{
      try{                    
        const data = await serviceCrm._itemsList(dato,'clientes');   
      
        return { response: data }
      }catch(error){                  
          return thunkAPI.rejectWithValue();           
      }   
  }
)

export const clientesCreate = createAsyncThunk(
  "clientes/create",
  async(dato,thunkAPI)=>{
      try{      
          const data = await serviceCrm._create(dato,'clientes');                                      
          toastr.success('Cliente', 'Dato creado') 
          return { response: data }
      }catch(error){
        const {response} = error        
        toastr.error('Cliente', response.data.message)                  
          return thunkAPI.rejectWithValue();           
      }   
  }
)

export const clientesUpdate = createAsyncThunk(
  "clientes/update",
  async(dato,thunkAPI)=>{
      try{      
          const data = await serviceCrm._update(dato,'clientes');  
          toastr.success('Cliente', 'Actualizado')                                     
          return { response: data }
      }catch(error){                  
          return thunkAPI.rejectWithValue();           
      }   
  }
)
export const clientesItem = createAsyncThunk(
  "clientes/item",
  async(pky,thunkAPI)=>{    
      try{      
          const item = await serviceCrm._item(pky,'consultas');    
                                 
          return { response: item }
      }catch(error){                  
          return thunkAPI.rejectWithValue();           
      }   
  }
)

export const clienteItem = createAsyncThunk(
  "cliente/item",
  async(pky,thunkAPI)=>{    
      try{      
          const item = await serviceCrm._titem(pky,'clientes');                                     
          return { response: item }
      }catch(error){                  
          return thunkAPI.rejectWithValue();           
      }   
  }
)
export const clientesDelete = createAsyncThunk(
  "clientes/delete",
  async(dato,thunkAPI)=>{    
      try{      
          const item = await serviceCrm._delete(dato,'clientes');     
          toastr.warning('Cliente', 'Eliminado')                              
          return { response: item }
      }catch(error){                  
          toastr.error('Cliente', 'No se puede eliminar')                               
          return thunkAPI.rejectWithValue();           
      }   
  }
)
export const estadosData = createAsyncThunk(
  "clientes/estados",
  async(dato,thunkAPI)=>{
      try{                    
        const data = await serviceCrm._informes(dato,'estados');       
                
        return { response: data }
      }catch(error){                  
          return thunkAPI.rejectWithValue();           
      }   
  }
)

const initialState = { 
    loading:false,    
    item:{
      
    },
    items:[],  
    jobs:[],  
    data:[],
    total:0,
    pagina:0,
    paginas:0,
    _data:[],
    _total:0,
    _pagina:0,
    _paginas:0,
    estados:[],
    scliente:{},    
    pTotal:0,
    pSaldo:0,
    pPago:0,
    lciudad:"" ,
    ind:0   
  };  
  const clienteSlice = createSlice({
    name: "cliente",
    initialState,  
    reducers:{
      resetItem:(state)=>{
        state.item = {}
      },
      setLciudad:(state,action)=>{
        state.lciudad = action.payload
      },
      setInd:(state,action)=>{
        state.ind = action.payload
      },
      setCliente:(state,action)=>{      
        state.item = action.payload
      },
      resetData:(state)=>{
        state.data    = []
        state.total   = 0
        state.pagina  = 0
        state.paginas = 0
        state.estados  = []
        state.scliente = {}    
        state.pTotal   = 0
        state.pSaldo   = 0
        state.pPago    = 0
        state.items    = []
        state.jobs     = []
        state.item     = {}
      },    
    },
    extraReducers(builder) { 
        builder
        .addCase(clientesData.pending,(state) =>{
            state.loading = true            
        })
        .addCase(clientesData.fulfilled,(state,action)=>{
            state.loading = false
            state.data  = action.payload.response.data    
            state.total = action.payload.response.total          
            state.pagina = action.payload.response.pagina          
            state.paginas = action.payload.response.paginas          
        })
        .addCase(clientesData.rejected,(state)=>{
          state.loading = false
         
        }) 
        .addCase(jobsData.pending,(state) =>{
          state.loading = true            
      })
      .addCase(jobsData.fulfilled,(state,action)=>{
          state.loading = false
          state.jobs  = action.payload.response
      })
      .addCase(jobsData.rejected,(state)=>{
        state.loading = false
       
      }) 
        .addCase(productosData.pending,(state) =>{
          state.loading = true            
        })
        .addCase(productosData.fulfilled,(state,action)=>{
            state.loading  = false
            state._data    = action.payload.response.data    
            state._total   = action.payload.response.total          
            state._pagina  = action.payload.response.pagina          
            state._paginas = action.payload.response.paginas            
        })
        .addCase(productosData.rejected,(state)=>{
          state.loading = false
        
        })

        .addCase(clientesDelete.pending,(state) =>{
          state.loading = true            
      })
      .addCase(clientesDelete.fulfilled,(state,action)=>{
          state.loading = false
          state.data  = action.payload.response.data    
          state.total = action.payload.response.total          
          state.pagina = action.payload.response.pagina          
          state.paginas = action.payload.response.paginas          
      })
      .addCase(clientesDelete.rejected,(state)=>{
        state.loading = false
       
      }) 
        .addCase(clientesCreate.pending,(state) =>{
          state.loading = true            
          state.item    = {}
        })
        .addCase(clientesCreate.fulfilled,(state,action)=>{
            state.loading = false
            state.item    = action.payload.response              
        })
        .addCase(clientesCreate.rejected,(state)=>{
          state.loading = false            
        })
        /** update */
        .addCase(clientesUpdate.pending,(state) =>{
          state.loading = true            
          state.item    = {}
        })
        .addCase(clientesUpdate.fulfilled,(state,action)=>{
            state.loading = false
            state.item    = action.payload.response              
        })
        .addCase(clientesUpdate.rejected,(state)=>{
          state.loading = false
          state.item    = {}
        })
        
        .addCase(clientesItem.pending,(state) =>{
          state.loading = true            
          state.item    = {}
        })
        .addCase(clientesItem.fulfilled,(state,action)=>{
            state.loading = false
            state.item    = action.payload.response.item
            state.items   = action.payload.response.items.data
            
        })
        .addCase(clientesItem.rejected,(state)=>{
          state.loading = false            
        })

        .addCase(clienteItem.pending,(state) =>{
          state.loading = true            
          state.item    = {}
        })
        .addCase(clienteItem.fulfilled,(state,action)=>{
            state.loading = false
            state.item    = action.payload.response
            
        })
        .addCase(clienteItem.rejected,(state)=>{
          state.loading = false            
        })

        .addCase(clientesItems.pending,(state) =>{
          state.loading = true            
        })
        .addCase(clientesItems.fulfilled,(state,action)=>{
          state.loading = false
          state.items  = action.payload.response                      
        })
        .addCase(clientesItems.rejected,(state)=>{
          state.loading = false     
        })
        .addCase(estadosData.pending,(state) =>{
          state.loading = true            
        })
        .addCase(estadosData.fulfilled,(state,action)=>{
            state.loading   = false
            state.estados   = action.payload.response.data          
            state.total     = action.payload.response.total
            state.pPago     = action.payload.response.pPago
            state.pSaldo    = action.payload.response.pSaldo
            state.pTotal    = action.payload.response.pTotal
            state.scliente  = action.payload.response.cliente
        })
        .addCase(estadosData.rejected,(state)=>{
          state.loading = false       
        })
      } 
    });
export const { resetItem, resetData, setCliente, setLciudad, setInd} = clienteSlice.actions
const { reducer } = clienteSlice;
export default reducer; 
