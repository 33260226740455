import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import serviceCrm  from "../services/serviceCrm"

export const catesItems = createAsyncThunk(
  "cate/items",
  async(dato,thunkAPI)=>{
      try{                    
        const data = await serviceCrm._litems(dato,'cates');                                                
        return { response: data }
      }catch(error){                  
          return thunkAPI.rejectWithValue();           
      }   
  }
)



const initialState = { 
    loading:false,
    data:[],
    c_items:[],
    total:0,
    pagina:0,
    paginas:0,
    item:{},    
}; 


const cateSlice = createSlice({
    name: "cate",
    initialState,  
    reducers:{
      setCates:(state,action)=>{      
        state.c_items = action.payload
      },
      resetItem:(state)=>{
        state.item ={}
      },
      resetData:(state)=>{
        state.data    = []  
        state.total   = 0
        state.pagina  = 0
        state.paginas = 0   
      },    
    },
    extraReducers(builder) { 
      builder
      
      .addCase(catesItems.pending,(state) =>{
        state.loading = true            
      })
      .addCase(catesItems.fulfilled,(state,action)=>{
          state.loading = false
          state.c_items  = action.payload.response
      })
      .addCase(catesItems.rejected,(state)=>{
        state.loading = false
      
      }) 
    }   
  });
  export const { resetItem, resetData, setCates } = cateSlice.actions
  const { reducer } = cateSlice;
  export default reducer;  
  