import Moment from "react-moment"
import { apiUrl } from '../../bundles/herlpers/api'
import { Link  } from 'react-router-dom'
import 'moment/locale/es';
/** Rev */

const TableJobs = ({data}) => {       

return (      
  <div className="h-max  w-full ">    
    <div className="h-350 overflow-auto items-center justify-center  pt-2 pl-2">
    { data.map((ite,index)=> (
      <div 
      key={index}       
      className='h-max w-64 rounded float-left flex-col mb-1 bg-stone-200 mr-4'>          
        <div className="h-12 w-full flex text-gray-100  bg-[#015eea]">
          <div className="h-10 w-10 flex items-center justify-center">
                <img alt=".."
                     className="h-8 w-8 rounded-full"          
                     src={`${apiUrl}static/images/clientes/sm/` + ite.filename}
                />
          </div>
          <div className="h-10 w-9/12 flex-col pl-1 flex justify-center ">
            <p className="h-6 pt-1 text-[11px] font-bold">{ ite.cliente}</p>
            <p className="h-4 text-[9px] ">
              Vencimiento: <Moment format="ll">{ ite.vencimiento}</Moment>
            </p>
          </div>          
        </div>  
        <div className="h-60 w-full flex bg-white flex-col text-[11px] text-gray-600 p-1">
            <h6 className="h-5 w-full font-bold bg-stone-100">Ciudad</h6>
            <div className="h-7 bg-stone-200 w-full flex pl-2 border  border-gray-300 items-center">
                {ite.ciudad}  
            </div>
            <h6 className="h-5 w-full font-bold bg-stone-100">Puesto</h6>
            <div className="h-12 bg-stone-200 w-full pl-2 border flex border-gray-300 items-center text-ellipsis overflow-hidden ">
                {ite.titulo}  
            </div>
            <h6 className="h-5 w-full font-bold bg-stone-100">Detalle</h6>
            <div className="h-16 bg-stone-200 w-full pl-2 border flex border-gray-300 items-center text-ellipsis overflow-hidden ">
                {ite.caracteristicas}  
            </div>
            <div className="h-9 w-full p-1 flex items-center justify-center">
              
                <Link to={ite.link} target="_blank"                
                className="h-7 w-16  bg-slate-500 rounded items-center text-gray-100 font-bold shadow-md flex justify-center">
                    Aplicar
                </Link>           
            </div>
        </div>     
    </div>
    ))}  
    </div>
   
    
  </div>  
  );
}

export default TableJobs;
